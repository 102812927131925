import "./skip-link-focus-fix";
import AOS from "aos";
import "script-loader!slick-carousel";
import "script-loader!lity";

(function ($) {
  AOS.init({ once: true, duration: 500 });

  // scroll to anchor id with header offset
  $("a[href*='#']").on("click", function (e) {
    if (!e.target.parentElement.classList.contains("link-tiles__link2")) {
      e.preventDefault();
      var offset = $("#masthead").outerHeight();
      var target = this.hash;
      if ($(this).data("offset") != undefined) offset = $(this).data("offset");
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: $(target).offset().top - offset,
          },
          500,
          "swing",
          function () {
            // window.location.hash = target;
          }
        );
    }
  });

  $(document).ready(function () {
    menuToggle();
    heroAccordion();
    gameBlockImageSlider();
    accordionShortcode();
    contentPopupSlider();
    characterTypes();
    productAdditionalImages();
    productInfoTabs();
    characterSlider();

    $(window).on("scroll", function () {
      headerEffects();
      animatedLineDrawing();
    });

    $(window).trigger("scroll");
  });

  // accordions
  $(".accordion-toggle").on("click", function () {
    $(this).toggleClass("arrow-down arrow-up");
    $(this).siblings(".accordion-toggled").slideToggle("fast");
  });

  // Header scroll effects
  function headerEffects() {
    var header = $("#masthead"),
      scrollPosition = $(window).scrollTop();

    if (scrollPosition > 100) header.addClass("site-header--scrolled");
    else header.removeClass("site-header--scrolled");
  }

  // Header menu toggle
  function menuToggle() {
    $("#menu-toggle").on("click", function () {
      $(this).toggleClass("site-header__menu-toggle--open");
      $("#menu-primary-menu").toggleClass("site-header__menu--open");
    });

    $(".submenu-toggle").on("click", function () {
      var submenu = $(this).next(".sub-menu");
      $(this).toggleClass("submenu-toggle--open");
      submenu.slideToggle();
    });
  }

  // Hero accordion toggle
  function heroAccordion() {
    $("#heroAccordionToggle").on("click", function () {
      $(this).toggleClass("home-hero__accordion-toggle--open");
      $("#heroAccordionContent").slideToggle();
    });
  }

  // Game block image slider
  function gameBlockImageSlider() {
    $(".game-block__slider").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      speed: 500,
      autoplay: false,
      adaptiveHeight: false,
      dots: true,
    });
  }

  function characterSlider() {
    $(".fourby .charslid1").slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      speed: 500,
      autoplay: false,
      adaptiveHeight: false,
      dots: true,
    });

    $(".fourby .charslid2").slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      speed: 500,
      autoplay: false,
      adaptiveHeight: false,
      dots: true,
    });

    $(".threeby .charslid1").slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      speed: 500,
      autoplay: false,
      adaptiveHeight: false,
      dots: true,
    });

    $(".threeby .charslid2").slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      speed: 500,
      autoplay: false,
      adaptiveHeight: false,
      dots: true,
    });

    $(".twoby .charslid1").slick({
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: true,
      speed: 500,
      autoplay: false,
      adaptiveHeight: false,
      dots: true,
    });

    $(".twoby .charslid2").slick({
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: true,
      speed: 500,
      autoplay: false,
      adaptiveHeight: false,
      dots: true,
    });

    $(".oneby .charslid1").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      speed: 500,
      autoplay: false,
      adaptiveHeight: false,
      dots: true,
    });

    $(".oneby .charslid2").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      speed: 500,
      autoplay: false,
      adaptiveHeight: false,
      dots: true,
    });

    const charslid1 = document.querySelectorAll(".charslid1");
    const charslid2 = document.querySelectorAll(".charslid2");
    const charselect1 = document.querySelector(
      ".char-slider__box-left__select-left"
    );
    const charselect2 = document.querySelector(
      ".char-slider__box-left__select-right"
    );
    const charselect = document.querySelector(".char-slider__box-left__select");

    if (charselect) {
      charselect.addEventListener(
        "click",
        (e) => {
          if (
            e.target === charselect1 ||
            e.target.classList.contains("charselect-text1")
          ) {
            if (!charselect1.classList.contains("active")) {
              charselect1.classList.add("active");
              charslid1.forEach((i) => {
                i.classList.add("active");
              });
              charselect2.classList.remove("active");
              charslid2.forEach((i) => {
                i.classList.remove("active");
              });
            }
          }
          if (
            e.target === charselect2 ||
            e.target.classList.contains("charselect-text2")
          ) {
            if (!charselect2.classList.contains("active")) {
              charselect2.classList.add("active");
              charslid2.forEach((i) => {
                i.classList.add("active");
              });
              charselect1.classList.remove("active");
              charslid1.forEach((i) => {
                i.classList.remove("active");
              });
            }
          }
        },
        false
      );

      const viewAllChars = document.querySelector("#viewAllChars");
      const vacPlus = document.querySelector(".vacPlus");
      viewAllChars.addEventListener("mouseover", (e) => {
        vacPlus.classList.add("active");
      });

      viewAllChars.addEventListener("mouseout", (e) => {
        vacPlus.classList.remove("active");
      });
    }
  }

  // Content popup slider
  function contentPopupSlider() {
    $(document).on("lity:open", function (event, instance) {
      var popupSlider = $(event.target).find(".content-popup__popup");
      if (popupSlider) {
        $(".content-popup__slider").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 500,
          autoplay: false,
          adaptiveHeight: true,
          dots: true,
        });
      }
    });

    $(document).on("lity:close", function (event, instance) {
      var popupSlider = $(event.target).find(".content-popup__popup");
      if (popupSlider) {
        $(".content-popup__slider").slick("unslick");
      }
    });
  }

  // Accordion shortcode
  function accordionShortcode() {
    $(".accordion-shortcode__toggle").on("click", function () {
      var content = $(this).prev(".accordion-shortcode__content");

      console.log(content);
      content.slideToggle();
      $(this).toggleClass("accordion-shortcode__toggle--open");
    });
  }

  // Line drawing
  function animatedLineDrawing() {
    $(".animated-path").each(function () {
      var path = $(this)[0];

      var viewportHeight = $(window).height(),
        scrollTop = $(window).scrollTop(),
        elementOffsetTop = $(this).offset().top,
        elementHeight = $(this).height(),
        percentageSeen;

      if (elementOffsetTop > scrollTop + viewportHeight) {
        percentageSeen = 0;
      } else if (elementOffsetTop + elementHeight < scrollTop) {
        percentageSeen = 1;
      } else {
        var distance = scrollTop + viewportHeight - elementOffsetTop;
        var percentage = distance / ((viewportHeight + elementHeight) / 100);
        percentageSeen = percentage / 100 + 0.1;
      }

      var pathLength = path.getTotalLength();

      path.style.strokeDasharray = pathLength + " " + pathLength;

      path.style.strokeDashoffset = pathLength;

      path.getBoundingClientRect();

      var drawLength = pathLength * percentageSeen;

      path.style.strokeDashoffset = pathLength - drawLength;

      if (percentageSeen >= 0.99) {
        path.style.strokeDasharray = "none";
      } else {
        path.style.strokeDasharray = pathLength + " " + pathLength;
      }
    });
  }

  // Character type block toggles
  function characterTypes() {
    $(".character-types__toggle").on("click", function () {
      var type = $(this).data("type"),
        typesContent = $("#character-types").find(".character-types__type"),
        typesToggles = $(this).siblings(".character-types__toggle");

      typesToggles.removeClass("character-types__toggle--open");
      $(this).addClass("character-types__toggle--open");
      typesContent.hide();
      typesContent.filter('[data-type="' + type + '"]').show();
    });
  }

  // Single product additional images
  function productAdditionalImages() {
    var slider = $("#product-slider"),
      sliderNav = $("#product-slider-nav");

    slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      speed: 300,
      adaptiveHeight: true,
    });

    sliderNav.slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: "#product-slider",
      focusOnSelect: true,
      arrows: false,
      draggable: false,
    });

    slider.on("afterChange", function (event, slick, currentSlide) {
      sliderNav.slick("slickGoTo", currentSlide);
      var currrentNavSlideElem = sliderNav.find(
        '.slick-slide[data-slick-index="' + currentSlide + '"]'
      );
      sliderNav.find(".slick-slide.is-active").removeClass("is-active");
      $(currrentNavSlideElem).addClass("is-active");
    });
  }

  // Product info tabs
  function productInfoTabs() {
    $("#description-tab").on("click", function () {
      $(".product-infotabs__content").hide();
      $(".product-infotabs__tab").removeClass("product-infotabs__tab--open");
      $(this).addClass("product-infotabs__tab--open");
      $("#description-content").show();
    });

    $("#specifications-tab").on("click", function () {
      $(".product-infotabs__content").hide();
      $(".product-infotabs__tab").removeClass("product-infotabs__tab--open");
      $(this).addClass("product-infotabs__tab--open");
      $("#specifications-content").show();
    });

    $("#highlights-tab").on("click", function () {
      $(".product-infotabs__content").hide();
      $(".product-infotabs__tab").removeClass("product-infotabs__tab--open");
      $(this).addClass("product-infotabs__tab--open");
      $("#highlights-content").show();
    });
  }
})(jQuery);
